import { numberFormat } from 'core-utilities';

export default function formattingRobux(robuxAmount: number, highlight = true) {
  if (!highlight) {
    return `<span class='icon-robux-gray-16x16'></span><span class='text'>${numberFormat.getNumberFormat(
      robuxAmount
    )}</span>`;
  }
  return `<span class='icon-robux-16x16'></span><span class='text-robux'>${numberFormat.getNumberFormat(
    robuxAmount
  )}</span>`;
}
